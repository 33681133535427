<template lang="pug">
v-container
  FwdHeadingLarge ink colors
  BaseListSearchOnly(
    :items="itemsSorted"
    avatar=true
    searchLabel="search by color name or pms"
    @search="setSearch"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs6) {{item.name}}
        v-flex(xs6) pms: {{item.pms}}
    template(slot="title")
      |
  LocalDetail(
    :detail="detail"
    @cancel="cancel"
  )
</template>

<script>
import { InkColor } from '@/models'
import { mapFields } from 'vuex-map-fields'
import BaseListSearchOnly from '@/components/base/list-search-only'
import LocalDetail from './detail'

export default {
  components: { BaseListSearchOnly, LocalDetail },
  computed: {
    ...mapFields('inkColors', {
      items: 'items',
      item: 'item',
      detail: 'detail',
      search: 'search'
    }),
    itemsSorted () {
      // filter by search term
      let list = this.filterByProps(this.items, ['displayName'], this.search)
      // sort list by name
      return this.sortByString(list, 'name')
    }
  },
  methods: {
    add () {
      this.item = new InkColor()
      this.detail = true
    },
    select (item) {
      this.item = new InkColor(item)
      this.detail = true
    },
    cancel () {
      this.detail = false
    },
    setFilter (filter) {
      this.filter = filter
    },
    setSearch (search) {
      this.search = search
    }
  }
}
</script>
